/*
Ornaments
*/

@layer utilities {
    .chunky-border-t-md {
        @apply pt-3 sm:pt-5 border-t-3 border-gray-50-translucent;
    }
    .chunky-border-t-lg {
        @apply border-t-4 border-gray-80-translucent;
    }
}

/*
Diagonal box
https://9elements.com/blog/pure-css-diagonal-layouts/
*/
:root {
    --diagonal-magic-number: 0.03496; /* tan(4°)/2 */
    --diagonal-content-width: 100vw;
    --diagonal-skew-padding: calc(
        var(--diagonal-content-width) * var(--diagonal-magic-number)
    );
}
.diagonal-box {
    position: relative;
    z-index: 0;
    padding-top: var(--diagonal-skew-padding);
    padding-bottom: var(--diagonal-skew-padding);
}
.diagonal-box:before {
    @apply bg-gray-50-translucent;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    transform: skewY(-2deg);
    z-index: -1;
}

.quote-featured {
    @apply px-2 sm:px-0;
    position: relative;
    z-index: 0;
}

.quote-featured:before {
    @apply absolute -inset-x-4 -inset-y-6 sm:-inset-x-8 sm:-inset-y-12;
    @apply sm:-rotate-3 bg-gray-50-translucent dark:bg-black/20 rounded-md;
    content: '';
    z-index: -1;
}

@screen sm {
    .spotlight-card-image {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%);
    }
}

/*
Animations
*/
@layer base {
    :root {
        --anime-delay: 0;
    }
}
