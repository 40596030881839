/*
Additional Utilities
*/

/* Prevent uninitialized Alpine JS template from appearing */
[x-cloak] {
    display: none;
}

/*
Force an aspect ratio on an element
*/
@layer utilities {
    .aspect-box:before {
        content: '';
        width: 1px;
        margin-left: -1px;
        float: left;
        height: 0;
    }
    .aspect-box:after {
        content: '';
        display: table;
        clear: both;
    }
    .aspect-ratio-1-1:before {
        padding-top: 100%;
    }
    .aspect-ratio-16-9:before {
        padding-top: 56.25%;
    }
    .aspect-ratio-2-3:before {
        padding-top: 150%;
    }
    .aspect-ratio-3-2:before {
        padding-top: 66.67%;
    }
    .aspect-ratio-people-portrait:before {
        padding-top: 88.88%;
    }
}

.clearfix:after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}

/*
Custom background gradients
*/
@layer utilities {
    .bg-gradient-blue-fresh-star {
        background: svg-load(
                    '../../templates/_svg/flower-gradient-fresh-blue.svg'
                )
                no-repeat 93% 50%,
            linear-gradient(193.36deg, #abe0df 44.39%, #9bdcdc 99.41%);
        background-size: auto 113%, auto;
    }
    .bg-gradient-blue-fresh {
        background: linear-gradient(
            193.36deg,
            rgba(12, 195, 191, 0.308) 44.39%,
            rgba(14, 191, 191, 0.4) 99.41%
        );
    }
    .bg-gradient-navy-blue {
        background: linear-gradient(23.31deg, #001e3f 41.96%, #14305a 81.38%);
    }
    .bg-gradient-navy-blue-to-light {
        background: linear-gradient(88.39deg, #14305a 27.5%, #066a9b 86.65%);
    }
    .bg-gradient-teal-red-green {
        @apply bg-gradient-to-br from-teal/25 via-red/25 to-green/25;
    }
    .bg-gradient-nav {
        background: linear-gradient(90deg, #d9d9d9 0%, #e6e6e6 4%);
    }
    .bg-gradient-gray {
        background: linear-gradient(
            180deg,
            rgba(95, 95, 95, 1) 68%,
            rgba(95, 95, 95, 0) 100%
        );
    }

    .bg-brand-color {
        background-color: var(--background-color-brand, #abe3e2);
    }

    .animate-reversed {
        animation-direction: reverse;
    }
    .animate-pause {
        animation-play-state: paused;
    }
}

/* Temporary classes that should be built using Tailwind JIT */
.-mb-0\.15em {
    margin-bottom: -0.15em;
}
.text-1\.3em {
    font-size: 1.3em;
}
.text-1\.1em {
    font-size: 1.1em;
}
.text-1\.2em {
    font-size: 1.2em;
}

@screen sm {
    .sm\:min-h-370px {
        min-height: 370px;
    }
    .sm\:text-18px {
        font-size: 18px;
    }
}

@screen md {
    .md\:w-1\.1 {
        width: 110%;
    }
}
