/*
Layouts and Grids
*/

:root {
    --grid-columns: 6;
    --grid-gap: 20px;
    --grid-margin-right: 24px;
    --grid-margin-left: 24px;
    --grid-container: 475px;

    --grid-bleed-left: calc(var(--grid-margin-left) - var(--grid-gap));
    --grid-bleed-right: calc(var(--grid-margin-right) - var(--grid-gap));
    --grid-column-width: calc(
        (var(--grid-container) - (var(--grid-columns) - 1) * var(--grid-gap)) /
            var(--grid-columns)
    );
}

@screen xs {
    :root {
        --grid-gap: 30px;
        --grid-margin-left: 35px;
        --grid-margin-right: 35px;
        --grid-container: 700px;
    }
}

@screen sm {
    :root {
        --grid-columns: 12;
        --grid-gap: 40px;
        --grid-margin-left: 60px;
        --grid-margin-right: 60px;
        --grid-container: 1400px;
    }
}

@screen md {
    :root {
        --grid-margin-left: 80px;
        --grid-margin-right: 368px;
    }
}

@layer utilities {
    .mx-grid-page {
        margin-left: var(--grid-margin-left);
        margin-right: var(--grid-margin-left);
    }
    .pl-grid-page {
        padding-left: var(--grid-margin-left);
    }
    .pr-grid-page {
        padding-right: var(--grid-margin-right);
    }
    .px-grid-page {
        padding-left: var(--grid-margin-left);
        padding-right: var(--grid-margin-right);
    }
    .ml-grid-page {
        margin-left: var(--grid-margin-left);
    }
    .-ml-grid-page {
        margin-left: calc(var(--grid-margin-left) * -1);
    }
    .mr-grid-page {
        margin-left: var(--grid-margin-right);
    }
    .-mr-grid-page {
        margin-left: calc(var(--grid-margin-right) * -1);
    }

    .gap-grid-page {
        gap: var(--grid-gap);
    }
    .gap-x-grid-page {
        column-gap: var(--grid-gap);
    }
}

/* Outer structure of the page */
@layer utilities {
    .grid-page {
        display: grid;
        grid-template-columns:
            minmax(var(--grid-bleed-left), 1fr)
            repeat(var(--grid-columns), minmax(0, var(--grid-column-width)))
            minmax(var(--grid-bleed-right), 1fr);
        column-gap: var(--grid-gap);
    }
}

/* 
* .grid-canvas is the default inner 12-col grid
* .col-page-canvas is the default inner 12-col grid, relative to .page-grid
*/
.grid-canvas,
.col-page-canvas {
    display: grid;
    grid-template-columns: repeat(
        var(--grid-columns),
        minmax(0, var(--grid-column-width))
    );
    column-gap: var(--grid-gap);
}
.col-page-canvas {
    grid-column-start: 2;
    grid-column-end: 8;
}
@screen sm {
    .col-page-canvas {
        grid-column-end: 14;
    }
}

/* 
* .col-page-container: width of page canvas relative to .grid-page
* .col-page-primary: width of copy relative to .page-grid
* .col-primary: width of copy relative to .col-page-canvas
*/

.col-page-container {
    grid-column: 2 / span 6;
}
.col-page-primary {
    grid-column: 2 / span 6;
}
.col-primary {
    grid-column: 1 / -1;
}
.col-right-span-2 {
    grid-column: 2 / -2;
}

@screen sm {
    .col-page-container {
        grid-column: 2 / span 12;
    }
    .col-page-primary {
        grid-column: 3 / span 9;
    }
    .col-primary {
        grid-column: 2 / span 9;
    }
}

@screen md {
    .col-right-span-2 {
        grid-column: 13 / span 1;
    }
}

/*
Navigation
*/
@screen md {
    .page-nav {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        height: auto;
        width: calc(var(--grid-margin-right) - var(--grid-gap));
        z-index: 10;
    }
}

/*
Milestones
*/
.grid-milestone {
    position: relative;
    z-index: 0;
    display: grid;
    column-gap: 0.5rem;
    grid-template-columns: 1.875rem 0.625rem 66% 1fr;
}

@screen sm {
    .grid-milestone {
        display: grid;
        column-gap: 1rem;
        grid-template-columns: 2.5rem 0.625rem 66% 1fr;
    }
}

/* Border connecting the milestone dots */
.grid-milestone:before {
    @apply border-l border-gray-80-translucent;
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 2.6rem;
    z-index: -1;
}

.dark .grid-milestone:before {
    @apply border-slate-500;
}

.timeline li:first-child .grid-milestone:before {
    top: 1.2rem;
}

@screen sm {
    .grid-milestone:before {
        left: 3.75rem;
    }
    .timeline li:first-child .grid-milestone:before {
        top: 1.6rem;
    }
}

.intrinsic-left-sidebar {
    display: flex;
    flex-wrap: wrap;
    /* Sidebar */
    & > :first-child {
        flex-basis: 20rem;
        flex-grow: 1;
    }
    /* Main Content */
    & > :last-child {
        flex-basis: 0;
        flex-grow: 999;
        min-inline-size: 50%;
    }
}
