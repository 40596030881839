/*
Custom Components
*/

/* Fluid embed iframes */
.responsive-embed {
    position: relative;
    iframe {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}

/*
Lazyloading images etc.
*/

.lazyload,
.lazyloading {
    transition: all 0.3s;
    opacity: 0.3;
    background: transparent no-repeat scroll center center;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='38' height='38' xmlns='http://www.w3.org/2000/svg' stroke='%23CCCCCC'%3E%3Cg transform='translate(1 1)' stroke-width='2' fill='none' fill-rule='evenodd'%3E%3Ccircle stroke-opacity='.5' cx='18' cy='18' r='18'/%3E%3Cpath d='M36 18A18 18 0 0 0 18 0'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.9s' repeatCount='indefinite'/%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    background-size: auto 25%;
}
.lazyloaded {
    transition: all 0.3s;
    opacity: 1;
    background-image: none;
}

.nav-scrollbar {
    --scrollbar-foreground: rgba(255, 255, 255, 0.3);
    --scrollbar-background: transparent;
    /* Foreground, Background */
    scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);
}

.nav-scrollbar::-webkit-scrollbar {
    width: 3px; /* Mostly for vertical scrollbars */
    height: 3px; /* Mostly for horizontal scrollbars */
}

.nav-scrollbar::-webkit-scrollbar-thumb {
    /* Foreground */
    background: var(--scrollbar-foreground);
}

.nav-scrollbar::-webkit-scrollbar-track {
    /* Background */
    background: var(--scrollbar-background);
}

/*
Scrolling marquee
*/

.marquee {
    --marquee-gap: theme(spacing.6);
    @media screen(sm) {
        --marquee-gap: theme(spacing.8);
    }

    @apply flex overflow-hidden select-none;
    gap: var(--marquee-gap);
}

.marquee-content {
    @apply flex shrink-0 justify-around min-w-full;
    gap: var(--marquee-gap);
}

@keyframes marquee {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(calc(-100% - var(--marquee-gap)));
    }
}

.animate-marquee {
    animation: marquee 30s linear infinite;
}

@media screen(sm) {
    .animate-marquee {
        animation-duration: 60s;
    }
}

@media (prefers-reduced-motion) {
    .marquee {
        overflow: auto;
    }
    .animate-marquee {
        animation: none;
    }
}

/*
Upvote Plugin
*/
.upvote-el.upvote-vote-match {
    @apply text-red;
}

/*
Icons
*/
.icon-arrow-right-circle {
    background-image: svg-load(
        '../../templates/_svg/icons/arrow-right-circle.svg',
        fill= '#5DA0BE'
    );
}

.icon-calendar {
    background-image: svg-load(
        '../../templates/_svg/icons/calendar.svg',
        fill= '#7A7A7A'
    );
}

.icon-location {
    background-image: svg-load(
        '../../templates/_svg/icons/location.svg',
        fill= '#7A7A7A'
    );
}

.icon-hand-shake {
    background-image: svg-load(
        '../../templates/_svg/icons/hand-shake.svg',
        fill= 'none',
        stroke= '#7A7A7A'
    );
}

.icon-circle {
    background-image: svg-load(
        '../../templates/_svg/icons/circle.svg',
        fill= '#9EBC73'
    );
}

/* YouTube lite play button Overrides */
lite-youtube .lty-playbtn {
    filter: grayscale(0) !important;
}

lite-youtube {
    @apply max-w-full !important;
}

.toc-chapter + .toc-chapter {
    @apply pt-3 border-t border-dotted border-black/30;
}
.toc-section + .toc-chapter {
    @apply mt-4;
}
.toc-section + .toc-chapter + .toc-section {
    @apply mt-4;
}
