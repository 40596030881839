/*
Forms
*/

/* Import default Formie CSS */
@import '../../vendor/verbb/formie/src/web/assets/frontend/dist/css/formie-theme.css';

.fui-i {
    --fui-font: theme(fontFamily.prose);
    --fui-font-size: theme(fontSize.md);
    --fui-row-gutter: theme(margin.10);
    --fui-input-padding: theme(padding.2);
    --fui-input-font-size: theme(fontSize.lg);
    --fui-label-line-height: theme(lineHeight.snug);
    --fui-primary-color: theme(colors.navy.dark);
    --fui-primary-color-hover: theme(colors.navy.light);
    --fui-border-radius: theme(borderRadius.md);
    --fui-border-color: theme(colors.gray.light);

    --fui-label-margin: theme(margin.2);
    --fui-label-color: theme(colors.gray.dark);

    --fui-instructions-font-size: theme(fontSize.base);
    --fui-instructions-color: theme(colors.gray.dark);
    --fui-instructions-margin: theme(margin.4);

    --fui-error-font-size: theme(fontSize.md);
    --fui-error-color: theme(colors.red.DEFAULT);
    --fui-label-error-color: theme(colors.red.DEFAULT);

    --fui-alert-font-size: theme(fontSize.xl);
    --fui-alert-line-height: theme(lineHeight.snug);
    --fui-alert-error-bg-color: theme(colors.gray.50.translucent);
    --fui-alert-margin-bottom: theme(margin.12);
    --fui-alert-padding: theme(padding.6);

    --fui-tabs-margin-bottom: theme(margin.2);
    --fui-tabs-border-bottom: none;
    --fui-tab-margin-bottom: 0;
    --fui-tab-padding: 0;
    --fui-tab-color: theme(colors.gray.dark);
    --fui-tab-active-font-weight: 600;

    --fui-check-font-size: theme(fontSize.md);
    --fui-check-label-padding-left: theme(padding.6);
    --fui-check-label-top: 3px;

    --fui-input-border-radius: theme(borderRadius.md);
    --fui-btn-font-size: theme(fontSize.lg);
    --fui-repeater-add-btn-top: 16px;
}

.fui-i {
    .fui-form-container {
        @apply bg-gray-50-translucent;
    }

    .fui-page-container {
        @apply pt-12 pl-grid-page pr-grid-page sm:px-12;
    }

    .fui-btn-container,
    .fui-btn-wrapper {
        @apply pb-8 pl-grid-page pr-grid-page sm:px-12;
    }

    .fui-btn-container.fui-btn-left,
    .fui-btn-container.fui-btn-center,
    .fui-btn-wrapper.fui-btn-left,
    .fui-btn-wrapper.fui-btn-center {
        @apply justify-end;
    }

    .fui-btn {
        @apply font-title font-semibold;
    }

    .fui-label,
    .fui-legend {
        @apply font-title text-base font-semibold;
    }

    .fui-tabs {
        @apply sticky top-0 z-10;
        @apply pt-2 px-4 sm:px-0;
        @apply font-title leading-squeeze;
        @apply rounded text-gray-light bg-gray-50;
    }
    .fui-tabs > :last-child:after {
        content: none;
    }
    .fui-tab {
        @apply px-2 sm:px-3 py-1.5 sm:py-3 text-sm sm:text-base border-none;
    }
    .fui-tab-active {
        @apply text-navy-dark font-semibold bg-gray-50-translucent border-none rounded-md sm:rounded-none sm:rounded-t-md;
    }

    .fui-alert {
        @apply mx-4 sm:mx-0;
    }
    .fui-alert-success {
        border: 1px solid theme(colors.blue.fresh.DEFAULT);
    }
    .fui-alert-error {
        border: 2px solid theme(colors.red.light);
    }
    .fui-limit {
        @apply mt-1 mb-0 text-sm !important;
    }
    .fui-instructions {
        @apply -mt-1.5;
    }

    .fui-checkbox-label,
    .fui-radio-label {
        padding: theme(padding.1) theme(padding.2) theme(padding.1)
            theme(padding.7);
        margin-left: -4px;
    }
    .fui-checkbox-label:hover,
    .fui-radio-label:hover {
        @apply bg-gray-50-translucent rounded;
    }
    .fui-radio label:before,
    .fui-checkbox label:before {
        top: 8px;
        left: 4px;
    }

    .fui-repeater-add-btn {
        @apply bg-gray-80-translucent;
    }
    .fui-repeater-remove-btn {
        @apply border-gray-dark border-2;
    }
    .fui-repeater-row {
        --fui-row-gutter: theme(margin.6);
        .fui-label,
        .fui-legend {
            @apply text-base;
        }
    }

    .fui-heading-h2 {
        @apply title-alt text-2xl;
    }
    .fui-heading-h3 {
        @apply title-alt text-xl;
    }
}

@screen sm {
    .fui-i {
        --fui-instructions-font-size: theme(fontSize.md);

        .fui-heading-h2 {
            @apply title-alt text-3xl;
        }
        .fui-heading-h3 {
            @apply title-alt text-2xl;
        }
        .fui-label,
        .fui-legend {
            @apply text-md;
        }
    }
}

.dark .fui-i {
    --fui-color: theme(colors.white);
    --fui-label-color: theme(colors.white);
    --fui-instructions-color: theme(colors.gray.light);
    --fui-input-background-color: rgba(255, 255, 255, 0.2);
    --fui-border-color: transparent;
    --fui-submit-btn-border-color: rgba(255, 255, 255, 0.4);
    --fui-tab-color: theme(colors.white);
    --fui-btn-bg-color: rgba(0, 0, 0, 0.2);
    --fui-btn-bg-color-hover: rgba(0, 0, 0, 0.4);
    --fui-repeater-row-border: rgba(255, 255, 255, 0.4) 1px solid;
    --fui-error-color: theme(colors.red.light);
    --fui-label-error-color: theme(colors.red.light);
    --fui-alert-error-color: theme(colors.red.light);

    .fui-form-container {
        @apply bg-black/10;
    }

    .fui-tabs {
        @apply bg-navy-dark;
    }
    .fui-tab-active {
        color: theme(colors.white);
        background-color: transparent;
        border-bottom: 2px solid theme(colors.white);
    }

    .fui-heading,
    .fui-heading-h2,
    .fui-heading-h3 {
        color: #fff;
    }

    .fui-repeater-add-btn {
        @apply bg-black/40;
    }
    .fui-repeater-add-btn:hover {
        @apply bg-black/60;
    }
    .fui-repeater-add-btn::after {
        content: '+';
        background: none;
        top: 0.4em;
    }

    .fui-repeater-remove-btn {
        border: none;
        background-color: rgba(255, 255, 255, 0.4);
    }
    .fui-repeater-remove-btn:hover {
        background-color: theme(colors.white);
    }
}
