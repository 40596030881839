/*
Base
*/

html {
    scroll-behavior: smooth;
}

/*
Defaults
*/

html {
    @apply bg-navy-light;
}
p {
    @apply mt-0;
}

/* lazysizes auto width calculation */
picture img[data-sizes='auto'] {
    @apply block w-full;
}
picture img.w-auto[data-sizes='auto'] {
    width: auto;
}
